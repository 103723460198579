<template>
  <div class="page1">
    <Coldiv direction="left">
      <div style="margin-top: 3.23vh">
        <Subtitle text="环境数据"></Subtitle>
        <div class="environmentDataBox">
          <div class="sunriseTableBox">
            <div class="sunriseTable">
              <img
                src="@/assets/page1/semiCircle.png"
                class="sunriseBgp"
                alt=""
              />
              <img src="@/assets/page1/fan.png" class="sunriseFan" alt="" />
              <img src="@/assets/page1/日出.png" class="sunriseIcon" alt="" />
              <img src="@/assets/page1/日落.png" class="sunsetIcon" alt="" />
              <span class="sunriseTime">{{ environmentData.sunriseTime }}</span>
              <span class="sunsetTime">{{ environmentData.sunsetTime }}</span>
            </div>
            <div class="windDirectionBox">
              <img
                src="@/assets/page1/风向.png"
                class="windDirectionIcon"
                alt=""
              />

              <span class="windDirectionText"
                >今日风向：{{ environmentData.windDirection }}</span
              >
            </div>
          </div>
          <div class="envFigs">
            <div class="qualityFigure">
              <img
                src="@/assets/page1/Frame 427319260.png"
                class="qualityIcon"
                alt=""
              />
              <div class="qualityInfoBox">
                <div class="qualityName">
                  <div class="qualityNameText">空气质量</div>
                  <div
                    class="qualityRank"
                    :style="{ background: environmentData.airRankColor }"
                  >
                    {{ environmentData.airRank }}
                  </div>
                </div>
                <div class="qualityPointBox">
                  <active-number
                    class="qualityPoint"
                    :number="environmentData.airQuality"
                  ></active-number>
                  <div class="fen">分</div>
                </div>
              </div>
            </div>
            <div class="qualityFigure" style="margin-top: 14.28%">
              <img
                src="@/assets/page1/Frame 427319263.png"
                class="qualityIcon"
                alt=""
              />
              <div class="qualityInfoBox">
                <div class="qualityName">
                  <div class="qualityNameText">水质指数</div>
                  <div class="qualityRank" style="background: #249a38">
                    <!--{{environmentData.waterRank}}-->
                    优
                  </div>
                </div>
                <div class="qualityPointBox">
                  <active-number
                    class="qualityPoint"
                    :number="environmentData.waterQualityIndex"
                  ></active-number>
                  <div class="fen">μs/cm</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Subtitle text="社区概况"></Subtitle>
        <div class="commnityInfoBox">
          <div class="communityInfoRow">
            <info-card
              class="communityInfoCard"
              itemName="社区面积(m²)"
              :itemValue="communityInfo.communityArea"
              imgsrc="./社区概况社区面积.png"
            ></info-card>
            <info-card
              class="communityInfoCard"
              itemName="房屋总数(间)"
              :itemValue="communityInfo.houseNumber"
              imgsrc="./社区概况房间总数.png"
            ></info-card>
            <info-card
              class="communityInfoCard"
              itemName="总车辆数(辆)"
              :itemValue="communityInfo.carNumber"
              imgsrc="./社区概况总车辆数.png"
            ></info-card>
          </div>
          <div class="communityInfoRow" style="bottom: 0">
            <info-card
              class="communityInfoCard"
              itemName="人口总数(人)"
              :itemValue="communityInfo.populationNumber"
              imgsrc="./社区概况人口总数.png"
            ></info-card>
            <info-card
              class="communityInfoCard"
              itemName="社工人员(人)"
              :itemValue="communityInfo.socialWorker"
              imgsrc="./社区概况社工人员.png"
            ></info-card>
            <info-card
              class="communityInfoCard"
              itemName="网格员数(人)"
              :itemValue="communityInfo.gridmanNumber"
              imgsrc="./社区概况网格人员.png"
            ></info-card>
          </div>
        </div>
      </div>
      <div style="margin-top: 5.37vh">
        <Subtitle
          text="事件处理"
          :remark="'事件总数：' + eventTable.total"
        ></Subtitle>
        <div class="eventBox">
          <percent-ring
            class="percentRing"
            itemName="待处理"
            :percent="
              eventTable.total
                ? parseInt((eventTable.pending * 100) / eventTable.total)
                : 0
            "
            ringColor="#ffd03b"
            :events="eventTable.pending"
          ></percent-ring>
          <percent-ring
            class="percentRing"
            itemName="处理中"
            :percent="
              eventTable.total
                ? parseInt((eventTable.processing * 100) / eventTable.total)
                : 0
            "
            ringColor="#3dffff"
            :events="eventTable.processing"
          ></percent-ring>
          <percent-ring
            class="percentRing"
            itemName="已处理"
            :percent="
              eventTable.total
                ? parseInt((eventTable.completed * 100) / eventTable.total)
                : 0
            "
            ringColor="#63e7b7"
            :events="eventTable.completed"
          ></percent-ring>
          <percent-ring
            class="percentRing"
            itemName="作废"
            :percent="
              eventTable.total
                ? parseInt((eventTable.aborted * 100) / eventTable.total)
                : 0
            "
            ringColor="#d4deec"
            :events="eventTable.aborted"
          ></percent-ring>
        </div>
      </div>
      <div style="margin-top: 4.91vh">
        <Subtitle text="关爱保障"></Subtitle>
        <div class="guanaibaozhangBox">
          <div class="guanaiRow">
            <info-card-2
              class="guanaiCard"
              :itemName="carenesses.length ? carenesses[0].typeName : ''"
              :itemValue="carenesses.length ? carenesses[0].total : 0"
            ></info-card-2>
            <info-card-2
              class="guanaiCard"
              :itemName="carenesses.length ? carenesses[1].typeName : ''"
              :itemValue="carenesses.length ? carenesses[1].total : 0"
            ></info-card-2>
            <info-card-2
              class="guanaiCard"
              :itemName="carenesses.length ? carenesses[2].typeName : ''"
              :itemValue="carenesses.length ? carenesses[2].total : 0"
            ></info-card-2>
          </div>
          <div class="guanaiRow">
            <info-card-2
              class="guanaiCard"
              :itemName="carenesses.length ? carenesses[3].typeName : ''"
              :itemValue="carenesses.length ? carenesses[3].total : 0"
            ></info-card-2>
            <info-card-2
              class="guanaiCard"
              :itemName="carenesses.length ? carenesses[4].typeName : ''"
              :itemValue="carenesses.length ? carenesses[4].total : 0"
            ></info-card-2>
            <info-card-2
              class="guanaiCard"
              :itemName="carenesses.length ? carenesses[5].typeName : ''"
              :itemValue="carenesses.length ? carenesses[5].total : 0"
            ></info-card-2>
          </div>
          <div class="guanaiRow">
            <info-card-2
              class="guanaiCard"
              :itemName="carenesses.length ? carenesses[6].typeName : ''"
              :itemValue="carenesses.length ? carenesses[6].total : 0"
            ></info-card-2>
            <info-card-2
              class="guanaiCard"
              :itemName="carenesses.length ? carenesses[7].typeName : ''"
              :itemValue="carenesses.length ? carenesses[7].total : 0"
            ></info-card-2>
            <!-- <info-card-2
              class="guanaiCard"
              :itemName="carenesses.length ? carenesses[8].typeName : ''"
              :itemValue="carenesses.length ? carenesses[8].total : 0"
            ></info-card-2> -->
            <div class="guanaiCard"><!-- 占位元素 --></div>
          </div>
        </div>
      </div>
    </Coldiv>
    <div class="noticeBox" v-show="showNotice">
      <div class="noticeTitle">社区公告</div>
      <div class="noticeText">{{ notice.item1 }}</div>
      <div class="noticeText">{{ notice.item2 }}</div>
    </div>
    <Coldiv direction="right">
      <div style="margin-top: 3.23vh">
        <Subtitle-red text="社区党建"></Subtitle-red>
        <div class="dangjianBox" @click="showPartyDetail">
          <div class="dangjianInfo">
            <div class="dangjianIconBox">
              <img src="@/assets/page1/党员.png" class="dangjianIcon" alt="" />
            </div>
            <div class="dangjianTextBox">
              <div class="dangjianText">党员数量</div>
              <active-number
                class="dangjianValue"
                :number="partyMembers"
              ></active-number>
            </div>
          </div>
          <div class="dangjianInfo">
            <div class="dangjianIconBox">
              <img
                src="@/assets/page1/党建活动.png"
                class="dangjianIcon"
                alt=""
              />
            </div>
            <div class="dangjianTextBox">
              <div class="dangjianText">党建活动</div>
              <active-number
                class="dangjianValue"
                :number="partyEvents"
              ></active-number>
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: 5.37vh">
        <Subtitle text="通行统计"></Subtitle>
        <div class="trafficStateBox">
          <percent-ring-2
            class="trafficStateCard"
            :percent="
              carTable.total
                ? parseInt((carTable.goIn * 100) / carTable.total)
                : 0
            "
            ringColor="#ffd03b"
            itemName="车辆进入"
            :events="carTable.goIn"
          ></percent-ring-2>
          <percent-ring-2-rev
            class="trafficStateCard"
            :percent="
              carTable.total
                ? parseInt((carTable.goOut * 100) / carTable.total)
                : 0
            "
            ringColor="#63e7b7"
            itemName="车辆出去"
            :events="carTable.goOut"
          ></percent-ring-2-rev>
        </div>
      </div>
      <div style="margin-top: 5.37vh">
        <Subtitle text="重点监控"></Subtitle>
        <div class="videoMonitorBox">
          <div class="mainCameraBox">
            <div class="mainCamera" @click="()=>{watchBigVideo(0,'ZM1-视频监控')}">
				<hls-camera :videoSrc="videoSrc[0].mainUrl" :vKey="0"></hls-camera>
            </div>
            <div class="mainCameraName">ZM1-视频监控</div>
          </div>
          <div class="cameraRow">
            <div class="smallCameraBox">
              <div class="smallCamera" @click="()=>{watchBigVideo(1,'ZM2-视频监控')}">
				<hls-camera :videoSrc="videoSrc[1].mainUrl" :vKey="1"></hls-camera>
              </div>
              <div class="cameraName">ZM2-视频监控</div>
            </div>
            <div class="smallCameraBox">
              <div class="smallCamera" @click="()=>{watchBigVideo(2,'ZM3-视频监控')}">
				<hls-camera :videoSrc="videoSrc[2].mainUrl" :vKey="2"></hls-camera>
              </div>
              <div class="cameraName">ZM3-视频监控</div>
            </div>
          </div>
          <div class="cameraRow">
            <div class="smallCameraBox">
              <div class="smallCamera" @click="()=>{watchBigVideo(3,'ZM4-视频监控')}">
				<hls-camera :videoSrc="videoSrc[3].mainUrl" :vKey="3"></hls-camera>
              </div>
              <div class="cameraName">ZM4-视频监控</div>
            </div>
            <div class="smallCameraBox">
              <div class="smallCamera" @click="()=>{watchBigVideo(4,'ZM5-视频监控')}">
				<hls-camera :videoSrc="videoSrc[4].mainUrl" :vKey="4"></hls-camera>
              </div>
              <div class="cameraName">ZM5-视频监控</div>
            </div>
          </div>
        </div>
      </div>
    </Coldiv>
  </div>
</template>

<script>
const Coldiv = () => import("./components/coldiv.vue");
const Subtitle = () => import("./components/subtitle.vue");
const SubtitleRed = () => import("./components/subtitleRed.vue");
const infoCard = () => import("./components/infoCard.vue");
const percentRing = () => import("./components/percentRing.vue");
const infoCard2 = () => import("./components/infoCard2.vue");
const percentRing2 = () => import("./components/percentRing2.vue");
const percentRing2Rev = () => import("./components/percentRing2Rev.vue");
const activeNumber = () => import("./components/activeNumber.vue");
const hlsCamera = () => import("./components/hlsCamera.vue");
export default {
  name: "page1",
  components: {
    Coldiv,
    Subtitle,
    infoCard,
    percentRing,
    infoCard2,
    SubtitleRed,
    percentRing2,
    percentRing2Rev,
    activeNumber,
	hlsCamera
  },
  data() {
    return {
      //模拟数据
		notice: {
			item1: '加载中',
			item2: '',
		},
		partyMembers: 0,
		partyMemberList: [],
		partyEvents: 0,
		carenesses: [],
		eventTable: {
			total: 0,
		},
		carTable: {
			goIn: 0,
			total: 0,
			goOut: 0,
		},
		environmentData: {
			sunriseTime: "6:40",
			sunsetTime: "17:20",
			windDirection: "加载中",
			waterQualityIndex: 0,
			airQuality: 0,
			temperature: 0,
			airRank: "优",
			airRankColor: "#249a38",
		},
		communityInfo: {
			communityArea: 0,
			houseNumber: 0,
			carNumber: 0,
			populationNumber: 0,
			socialWorker: 0,
			gridmanNumber: 0,
		},
		videoSrc: [
			//视频源地址列表
			{
				mainUrl: '',
				minorUrl: ''
			},{
				mainUrl: '',
				minorUrl: ''
			},{
				mainUrl: '',
				minorUrl: ''
			},{
				mainUrl: '',
				minorUrl: ''
			},{
				mainUrl: '',
				minorUrl: ''
			}
		],
		videoIsPlaying: [0, 0, 0, 0, 0],
		showNotice: true,
		pollingSIV: null,
    };
  },
  methods: {
    //获取关爱保障数据
    getCarenessData() {
      let sl = ["高龄","辍学","残疾","失业","低保","退役","独居","孤儿"];
      this.$api.loadClassifyList({
				pageSize: 99999999
			}).then(res => {
				let d = res.data.rows;
				let t = [];
				//初始化返回数组
				for(let k = 0;k < sl.length;k++){
					let obj = {
						typeName: sl[k],
						total: 0
					}
					t.push(obj);
				}
				for(let i = 0;i < d.length;i++){
					let p = d[i];
					let labels;
          if(p.label && p.label.length){
              labels = JSON.parse(p.label);
          }
					//获取到单个居民的标签数组，若标签存在，则对其进行分类操作，否则跳过
					if(labels && labels.length){
						for(let j = 0;j < labels.length;j++){
							let x = sl.indexOf(labels[j]);
							//只有当居民的标签在sl所列情况中才将居民进行分类。若其标签是楼栋长或者网格员等其他标签，则跳过
							//由于数据质量不佳，为避免本身带有高龄标签的情况出现而导致高龄居民重复出现，所以在这一步筛选掉x=0的情况，可筛选掉假高龄居民也可以避免重复
							if(x >= 1){
								t[x].total++;
							}
						}
					}
					//针对高龄的居民，获取其年龄，判断是否属于高龄者并纳入高龄类型，无需根据label来确定
					let {birthday} = p;
					let age = this.$u.getAge(birthday);
					//配置变量在utils/index.js修改
					if(age >= this.$c.highAgeIs){
						t[0].total++;
					}
				}
        //根据社区要求，把其中问题、艾滋、涉毒和监控的数据写死。若后期需要修改可以再调整
        /* t[4].total = 14; //问题
        t[5].total = 70; //涉毒
        t[6].total = 123; //艾滋
        t[8].total = 73; //监控 */

				this.carenesses = t;
				this.sKey1++;
			}).catch(err => {
				console.log(err);
			})
    },
	//显示党员党建详情
	showPartyDetail(){
		//通过事件总线控制Map.vue显示详情框，避免Map.vue中多个不同详情框冲突
		if(this.partyMemberList.length){
			this.$eventBus.$emit('showPartyDetail',this.partyMemberList);
		}
		return false;
	},
    //获取车辆进出列表
    getCarTable() {
		this.$api
			.getCarTable({
				pageSize: 99999999
			})
		.then((res) => {
			let d = res.data.rows;
			let o = {
				goIn: 0,
				goOut: 0,
				total: res.data.total,
			};
			for (let i = 0; i < d.length; i++) {
				let deviceName = d[i].passageway;
				if (deviceName.indexOf('入口') != -1) {
					o.goIn++;
				} else {
					o.goOut++;
				}
			}
      // 特殊需求
      let {goIn, goOut} = o;
      if(goIn > goOut + 60){
        goOut = goIn + 61;
      }else if(goOut > goIn + 60){
        goIn = goOut + 61;
      }
      o.goIn = goIn;
      o.goOut = goOut;
      o.total = goIn + goOut;
			this.carTable = o;
			d = null;
		})
		.catch((err) => {
			console.log(err);
		});
    },
	//获取事件列表
    getEventTable() {
      this.$api
        .getEventTable()
        .then((res) => {
          let d = res.data.rows;
          let o = {
            columns: [
              {
                title: "事件",
                key: "event",
                width: 31.32,
              },
              {
                title: "负责人",
                key: "director",
                width: 23.42,
              },
              {
                title: "地点",
                key: "position",
                width: 23.16,
              },
              {
                title: "状态",
                key: "status",
                width: 17.89,
              },
            ],
            data: [],
            processing: 0,
            completed: 0,
            pending: 0,
            verified: 0,
            aborted: 0,
            total: res.data.total,
          };
          let lmt;
          if (this.tableShowLimit && d.length > this.tableShowLimit) {
            lmt = this.tableShowLimit;
          } else {
            lmt = d.length;
          }
          for (let i = 0; i < lmt; i++) {
            let obj = {
              event: d[i].eventDescription,
              director: d[i].label,
              position: d[i].address,
              status: d[i].status,
            };
            if (d[i].status == "待处理") {
              obj.color = "#ff9118";
              o.pending++;
            } else if (d[i].status == "已处理") {
              obj.color = "#63e7b7";
              o.completed++;
            } else if (d[i].status == "处理中") {
              obj.color = "#3dffff";
              o.processing++;
            } else if (d[i].status == "已审核") {
              obj.color = "#63e7b7";
              o.verified++;
            }
            else {
              obj.color = "#ff3b3b";
              o.aborted++;
            }
            o.data.push(obj);
          }
          this.eventTable = o;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取社区概况
    getCommunityInfo() {
      this.$api
        .getCommunityInfo()
        .then((res) => {
          let d = res.data.data;
          this.communityInfo = d;
        })
        .catch((err) => {
          console.log(err);
        });
      return false;
    },
    //获取环境数据
    getEnvironmentData() {
      this.$api.getEnvironmentData().then(res => {
        let d = res.data.data;
        let aq = d.airQuality; //空气指数
        //let wq = d.waterQualityIndex;//水质指数
        if (aq < 60) {
        d.airRank = "差";
        d.airRankColor = "#ec7f00";
        } else if (aq >= 60 && aq < 80) {
        d.airRank = "良";
        d.airRankColor = "#0096d7";
        } else {
        d.airRank = "优";
        d.airRankColor = "#249a38";
        }
        let h = this.$u.formatDate()[3];
        //由于水质指数目前没有数据源，也没有明确水质标准，所以暂时采用一种伪随机算法生成数据
        h = parseInt(97*(Math.cos(7.3*h*h))%21);
        d.waterQualityIndex = 200 + h;
        this.environmentData = d;
		}).catch((err) => {
        console.log(err);
      });
    },
    //获取党员信息
    getPartyMembers() {
      this.$api.getPartyMembers({
          pageSize: 99999999,
        })
        .then(res => {
			let list = [];
			let r1=[], r2=[], r3=[], r4=[], r5=[], r6=[];
			let d = res.data.rows;
			for(let i = 0;i < d.length;i++){
				
				switch(d[i].type){
					case '机关党员':
						d[i].color = '#ff3b3b';
						r1.push(d[i]);
						break;
					case '两新党员':
						d[i].color = '#63e7b7';
						r2.push(d[i]);
						break;
					case '困弱党员':
						d[i].color = '#3dffff';
						r3.push(d[i]);
						break;
					case '流动党员':
						d[i].color = '#ff81b6';
						r4.push(d[i]);
						break;
					case '退役军党':
						d[i].color = '#f4c295';
						r5.push(d[i]);
						break;
					case '银龄党员':
						d[i].color = '#808dff';
						r6.push(d[i]);
						break;
				}
			}
			this.partyMembers = res.data.total;
			list.push(d);
			list.push(r1);
			list.push(r2);
			list.push(r3);
			list.push(r4);
			list.push(r5);
			list.push(r6);
			this.partyMemberList = list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取党建活动场数
    getPartyActivities(){
      this.$api.getPartyActivity().then(res => {
        let d = res.data.data;
        let count = d.activityNumber + d.studyNumber;
        this.partyEvents = count;
      }).catch(err => {
        console.log(err);
      })
    },
	//获取监控视频地址列表
	getVideo(arr) {
		this.$api.getCameraUrl({
			pageSize: 5
		}).then(res => {
			let d = res.data.rows;
			let list = [];
			for(let j = 0;j < 5;j++){
				list[j] = this.videoSrc[j];
			}
			let playing = this.videoIsPlaying;
			for(let a = 0;a < arr.length;a++){
				let i = arr[a];
				let item;
				if(this.$c.useExternalNetwork){
					//播放外网资源
					item = {
						mainUrl: this.$c.externalLiveBaseUrl + d[i].mainUrl,
						minorUrl: this.$c.externalLiveBaseUrl + d[i].minorUrl
					}
				}else{
					//播放内网资源
					item = {
						mainUrl: this.$c.intranetLiveBaseUrl + d[i].mainUrl,
						minorUrl: this.$c.intranetLiveBaseUrl + d[i].minorUrl
					}
				}
				playing[i] = 1;
				list[i] = item;
			}
			this.videoSrc = list;
			this.videoIsPlaying = playing;
			if(this.$c.useExternalNetwork){
				let sto = setTimeout(() => {
					//外网1分钟后停止直播，节约流量，刷新后再重新直播
					let list2 = [];
					for(let j = 0;j < 5;j++){
						list2[j] = this.videoSrc[j];
					}
					for(let a = 0;a < arr.length;a++){
						let i = arr[a];
						let item = {
							mainUrl: '',
							minorUrl: ''
						}
						list2[i] = item;
						this.videoIsPlaying[i] = 0;
					}
					this.videoSrc = list2;
					clearTimeout(sto);
				}, this.$c.videoTimeLimit);
			}
		}).catch(err => {
			console.log(err);
		})
	},
	//获取社区公告
	getNotice(){
		this.$api.getNotice({
			pageSize: 2
		}).then(res => {
			let d = res.data.rows;
			if(!d.length){
				this.notice = {
					item1: '暂无公告',
					item2: ''
				}
			}else if(d.length == 1){
				this.notice = {
					item1: d[0].title + '  ' + d[0].content,
					item2: ''
				}
			}else if(d.length >= 2){
				this.notice = {
					item1: d[0].title + '  ' + d[0].content,
					item2: d[1].title + '  ' + d[1].content
				}
			}
		}).catch(err => {
			console.log(err);
			this.notice = {
				item1: '加载失败，请刷新重试。',
				item2: ''
			}
		})
	},
  //观看放大化的监控视频画面
	watchBigVideo(i, n){
		if(this.videoIsPlaying[i]){
			//当点击正在播放的视频时才弹出大视频窗
			let url = this.videoSrc[i].mainUrl;
			let videoName = n;
			this.$eventBus.$emit('showBigVideo', {url, videoName});
		}else{
			//若点击的外网视频已经停止播放，则点击以后让它再次播放
			this.getVideo([i]);
		}
	},
  //页面初始化
	updatePageData(){
		this.getCarenessData();
		this.getEventTable();
		this.getCarTable();
		this.getCommunityInfo();
		this.getEnvironmentData();
		this.getPartyMembers();
		this.getVideo([0, 1, 2, 3, 4]);
		this.getPartyActivities();
		this.getNotice();
	}
  },
  watch: {},
  mounted() {
	this.$eventBus.$emit('switchPage', 1);
    this.updatePageData();
    this.pollingSIV = setInterval(() => {
      this.updatePageData();
    }, this.$c.pollingInterval)
    this.$eventBus.$on('closeNotice', () => {
      //事件源： Map.vue
      this.showNotice = false;
    });
    this.$eventBus.$on('openNotice', () => {
      this.showNotice = true;
    })
    },
    beforeDestroy() {
    clearInterval(this.pollingSIV);
  },
};
</script>

<style lang="less">
@import "~@/assets/less/index.less";
.page1 {
  height: 100%;
  width: 100%;
  background: none;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  transform: translateY(-1.11vh);
  z-index: 10;

  .noticeBox {
    margin-top: 3.23vh;
    width: 42.92%;
    height: 7.78vh;
    background: linear-gradient(
      90deg,
      rgba(72, 64, 42, 0.4) 0%,
      rgba(61, 61, 61, 0) 100%
    );
    border: 1px solid;
    border-image: linear-gradient(
        270deg,
        rgba(255, 208, 59, 1),
        rgba(255, 208, 59, 0.2)
      )
      1 1;

    .noticeTitle {
      height: 2.778vh;
      margin-left: 1.94%;
      margin-top: 0.37vh;
      font-family: "titleFont", sans-serif;
      text-shadow: 0px 0px 9px rgba(255, 208, 59, 0.7);
      background: linear-gradient(180deg, #ffffff 42%, #ffd03b 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 1.85vh;
      line-height: 2.78vh;
    }
    .noticeText {
      margin-left: 1.94%;
      height: 1.944vh;
      font-family: "pingsangSc", sans-serif;
      color: rgba(255, 255, 255, 0.8);
      font-size: 1.3vh;
      line-height: 1.944vh;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .environmentDataBox {
    width: 100%;
    height: 17.87vh;
    display: flex;
    align-items: center;

    .sunriseTableBox {
      width: 42.857%;
      height: 58.03%;
      margin-left: 7.38%;
    }

    .envFigs {
      height: 58.03%;
      width: 27.62%;
      margin-left: 11.9%;

      .qualityFigure {
        width: 100%;
        height: 42.85%;
        display: flex;
        align-items: center;

        .qualityIcon {
          height: 100%;
          aspect-ratio: 1/1;
        }

        .qualityInfoBox {
          width: 55.17%;
          height: 100%;
          margin-left: 3.45%;

          .qualityName {
            height: 45.46%;
            display: flex;
            align-items: center;

            .qualityNameText {
              height: 1.85vh;
              font-size: 1.3vh;
              font-family: "pingfangSc", sans-serif;
              line-height: 1.85vh;
              color: #fff;
              white-space: nowrap;
            }
            .qualityRank {
              height: 1.3vh;
              aspect-ratio: 1/1;
              color: #fff;
              font-size: 0.93vh;
              line-height: 1.3vh;
              font-family: "titleFont", sans-serif;
              background: #249a38;
              border-radius: 0.66vh;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 6.25%;
            }
          }
        }

        .qualityPointBox {
          height: 44.83%;
          width: 100%;
          display: flex;
          align-items: flex-end;

          .qualityPoint {
            font-size: 2.22vh;
            line-height: 2.22vh;
            height: 2.22vh;
            font-family: "titleFont", sans-serif;
            background: linear-gradient(180deg, #fff 42%, #ffd03b 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .fen {
            color: #fff;
            height: 1.58vh;
            line-height: 1.58vh;
            font-size: 1.11vh;
            font-family: "pingfangM", sans-serif;
            font-weight: 400;
            margin-left: 1px;
          }
        }
      }
    }

    .sunriseTable {
      position: relative;
      height: 76.92%;

      .sunriseBgp {
        position: absolute;
        z-index: 11;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .sunriseFan {
        position: absolute;
        z-index: 12;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
		opacity: 0.6
      }

      .sunriseIcon {
        position: absolute;
        z-index: 12;
        left: 6.67%;
        top: 24.44%;
        width: 8.89%;
        height: 17.78%;
      }
      .sunsetIcon {
        position: absolute;
        z-index: 12;
        left: 82.78%;
        top: 20%;
        width: 8.89%;
        height: 17.78%;
      }

      .sunriseTime {
        position: absolute;
        z-index: 12;
        left: -6.67%;
        top: 24.44%;
        font-family: "timerFont", sans-serif;
        font-size: 1.3vh;
        height: 100%;
        line-height: 100%;
        color: #fff;
      }

      .sunsetTime {
        position: absolute;
        z-index: 12;
        left: 92.77%;
        top: 20%;
        font-family: "timerFont", sans-serif;
        font-size: 1.3vh;
        height: 100%;
        line-height: 100%;
        color: #fff;
      }
    }
    .windDirectionBox {
      height: 11.4%;
      width: 100%;
      margin-top: 4.27%;
      display: flex;
      justify-content: center;
      align-items: center;

      .windDirectionIcon {
        height: 1.85vh;
        aspect-ratio: 1/1;
      }
      .windDirectionText {
        font-size: 1.3vh;
        line-height: 1.85vh;
        font-family: "pingfangSc", sans-serif;
        color: #fff;
      }
    }
  }

  .commnityInfoBox {
    margin-top: 1.85vh;
    margin-left: 4.76%;
    width: 90.48%;
    height: 12.22vh;
    position: relative;

    .communityInfoRow {
      position: absolute;
      height: 42.42%;
      width: 100%;
      display: flex;
      justify-content: space-between;

      .communityInfoCard {
        width: 31.05%;
      }
    }
  }

  .guanaibaozhangBox {
    margin-top: 1.85vh;
    width: 93.57%;
    height: 17.78vh;
    margin-left: 3.1%;

    .guanaiRow {
      width: 100%;
      height: 29.16%;
      display: flex;
      justify-content: space-between;

      .guanaiCard {
        width: 31.29%;
      }
    }
  }

  .eventBox {
    width: 92.85%;
    height: 9.44vh;
    margin-left: 3.57%;
    margin-top: 1.85vh;
    display: flex;
    justify-content: space-between;

    .percentRing {
      width: 23.08%;
    }
  }

  .dangjianBox {
    margin-top: 1.85vh;
    margin-left: 4.76%;
    width: 90.48%;
    height: 7.41vh;
    display: flex;
    justify-content: space-between;

    .dangjianInfo {
      height: 100%;
      width: 47.36%;
      display: flex;
      align-items: center;
		cursor: pointer;

      .dangjianIconBox {
        width: 45.56%;
        height: 85%;

        .dangjianIcon {
          width: 100%;
          height: 100%;
        }
      }
      .dangjianTextBox {
        height: 78.57%;
        margin-left: 5.56%;

        .dangjianText {
          font-family: "pingfangSc", sans-serif;
          height: 1.944vh;
          font-size: 1.3vh;
          line-height: 1.3vh;
          display: flex;
          align-items: center;
          color: rgba(255, 255, 255, 0.7);
          margin-top: 21.25%;
        }

        .dangjianValue {
          font-family: "titleFont", sans-serif;
          background: linear-gradient(180deg, #ffffff 42%, #ffd03b 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          line-height: 1.85vh;
          font-size: 1.85vh;
        }
      }
    }
  }

  .trafficStateBox {
    height: 7.41vh;
    width: 88.1%;
    margin-top: 1.85vh;
    margin-left: 5.95%;
    display: flex;
    justify-content: space-between;

    .trafficStateCard {
      width: 47.37%;
    }
  }

  .videoMonitorBox {
    width: 90.48%;
    height: 44.54vh;
    margin-top: 1.85vh;
    margin-left: 5.95%;

    .mainCameraBox {
      width: 100%;
      height: 45.11%;

      .mainCamera {
        height: 89.4%;
        width: 100%;
        border: #ffd03b 1px solid;
        cursor: pointer;
      }

      .mainCameraName {
        width: 100%;
        height: 1.944vh;
        margin-top: 0.92%;
        font-size: 1.3vh;
        line-height: 1.944vh;
        text-align: center;
        color: rgba(255, 255, 255, 0.7);
        font-family: "pingfangSc", sans-serif;
      }
    }
    .cameraRow {
      height: 23.91%;
      width: 100%;
      margin-top: 3.32%;
      display: flex;
      justify-content: space-between;

      .smallCameraBox {
        width: 46.58%;
        height: 100%;

        .smallCamera {
          width: 100%;
          height: 80%;
          border: #ffd03b 1px solid;
          cursor: pointer;
        }
        .cameraName {
          width: 100%;
          height: 1.944vh;
          font-size: 1.3vh;
          line-height: 1.944vh;
          line-height: 150%;
          text-align: center;
          color: rgba(255, 255, 255, 0.7);
          font-family: "pingfangSc", sans-serif;
        }
      }
    }
  }
}
</style>
